export const themeDetails = {
  company: 'Keminmaan Energia ja Vesi Oy',
  brandName: 'Keminmaan Energia ja Vesi Kulutusportaali',
  theme: 'kmev',
  services: ['districtheat', 'electricity', 'contractInfo'],
  incomingServices: ['water'],
  customerServiceEmail: 'info@kmev.fi',
  feedbackEmail: 'info@kmev.fi',
}

/* added property to control drillable value -ADP */
export const Options = {
  quarterlyDataAllowed: false, 
}

export const styledComponentsThemes = {
  default: {
    'nav-color': '#005685',
    'inline-nav-color': '#005685',
    'nav-color:hover': '#009fe3',
  },
  inverted: {
    'nav-color': '#fff',
    'inline-nav-color': '#fff',
    'nav-color:hover': '#fff',
  },
}

export const textStrings = {
  home: {
    introduction: 'Keminmaan Energia ja Vesi Kulutusportaali',
    subHeader:
      'Täältä löydät ajankohtaiset tiedot liittyen energiankulutukseesi.',
  },
}

export const footerContent = {
  contactInfo: [
    {
      icon: 'fa fa-map-marker-alt',
      type: 'address',
      href: '',
      title: 'Käyntiosoite',
      content: ['Jauholantie 4,', '99450 Keminmaa'],
    },
    {
      icon: 'fa fa-phone',
      type: 'tel',
      href: 'tel:0401870205',
      title: 'Sähkö ja kaukolämpö',
      content: 'puh. 040 187 0205',
    },
    {
      icon: 'fa fa-phone',
      type: 'tel',
      href: 'tel:0401870206',
      title: 'Vesi ja jätevesi',
      content: 'puh. 040 187 0206',
    },
    {
      icon: 'fa fa-globe',
      type: 'website',
      href: 'https://www.kmev.fi',
      title: 'Verkkosivut',
      content: 'www.kmev.fi',
    },
    {
      icon: 'fa fa-envelope',
      type: 'email',
      href: 'mailto:info@kmev.fi',
      title: 'Sähköpostiosoite',
      content: 'info@kmev.fi',
    },
  ],
  officeHours: ['ma-to: 8:00 - 15:15', 'pe: 8:00 - 13:30'],
  faultReports: {
    intro: 'Vikailmoitukset työajan ulkopuolella',
    contacts: [
      {
        href: 'tel:0400392589',
        text: 'Sähkö ja kaukolämpö: ',
        linkText: '040 039 2589',
      },
      {
        href: 'tel:0400935056',
        text: 'Vesi ja jätevesi: ',
        linkText: '040 093 5056',
      },
    ],
  },
  website: 'https://www.kmev.fi',
}
